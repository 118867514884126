/* App.css */
.answer {
  padding-left: 10px; /* Abstand vom linken Rand */
  max-width: 90vw; /* Maximale Breite auf 90% der Viewport-Breite setzen */
  overflow-wrap: break-word; /* Bricht lange Wörter um */
}

.typing-animation {
  font-family: "Courier New", Courier, monospace; /* Schreibmaschinenschriftart */
  font-size: calc(1vw + 1rem); /* Dynamische Schriftgröße */
  color: #333;
  white-space: nowrap; /* Verhindert Zeilenumbrüche während der Animation */
  overflow: hidden; /* Verhindert das Anzeigen von überlaufendem Text */
  border-right: 4px solid; /* Schreibmaschinen-Cursor-Effekt */
  animation: typing 10s steps(40, end), blink-caret 0.75s step-end infinite;
  max-width: 90vw; /* Maximale Breite auf 90% der Viewport-Breite setzen */
  text-align: left; /* Text linksbündig ausrichten */
  word-wrap: break-word; /* Bricht lange Wörter um */
  overflow-wrap: break-word; /* Alternative für bessere Browser-Kompatibilität */
}

/* Schreibmaschinenanimation */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Blinkender Cursor */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

/* Nach Abschluss der Animation */
.typing-animation.finished {
  white-space: normal; /* Erlaubt Zeilenumbrüche nach der Animation */
  border-right: none; /* Entfernt den Cursor-Effekt nach der Animation */
  animation: none; /* Entfernt die Schreibmaschinenanimation */
}
